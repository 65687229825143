<template>
  <DefaultTemplate>
    <div class="box-name-page">
      <div>
        <el-breadcrumb
          separator-class="el-icon-arrow-right"
          class="set-breadcrumb"
        >
          <el-breadcrumb-item :to="{ path: '/manage/project' }"
            >จัดการข้อมูลโครงการ</el-breadcrumb-item
          >
          <el-breadcrumb-item>เพิ่มข้อมูลโครงการ</el-breadcrumb-item>
        </el-breadcrumb>
        <p class="title mg-t-5">เพิ่มข้อมูลโครงการ</p>
      </div>
    </div>
    <el-form
      label-position="top"
      :model="form"
      class="set-form"
      :rules="rules"
      ref="form"
    >
      <Step1 @stepNow="formDataOut" :formData="form" />
      <Step2
        v-if="stepTwo"
        @projectAdd="projectAdd"
        :formData="form"
        :loadingSave="loadingSave"
      />
    </el-form>
  </DefaultTemplate>
</template>

<script>
import numeral from "numeral";
import DefaultTemplate from "@/template/DefaultTemplate";
import Step1 from "@/components/project/Step1";
import Step2 from "@/components/project/Step2";
import { HTTP } from "@/service/axios";
import { TERAK } from "@/service/axios";
import jwt from "jsonwebtoken";

export default {
  components: {
    DefaultTemplate,
    Step1,
    Step2,
  },
  data() {
    var validGrade = (rule, value, callback) => {
      if (this.form.grade.length == 0) {
        callback(new Error("กรุณาเลือกเกรด"));
      } else {
        callback();
      }
    };
    var validIncomeMax = (rule, value, callback) => {
      if (this.form.incomeMax === "") {
        callback(new Error("กรุณากรอกตัวเลขรายได้"));
      } else {
        if (
          parseInt(this.form.incomeMax) != 0 &&
          parseInt(this.form.incomeMin) != 0
        ) {
          if (parseInt(this.form.incomeMax) < parseInt(this.form.incomeMin)) {
            callback(new Error("รายได้สิ้นสุดน้อยกว่ารายได้เริ่มต้น"));
          } else if (parseInt(this.form.incomeMax) > 10000000000000) {
            callback(new Error("กรุณากรอกตัวเลขไม่เกิน 9 ล้านล้าน"));
          } else {
            callback();
          }
        } else {
          callback(new Error("รายได้สิ้นสุดต้องมากกว่า 0"));
        }
      }
    };
    var validIncomeMin = (rule, value, callback) => {
      if (this.form.incomeMin === "") {
        callback(new Error("กรุณากรอกตัวเลขรายได้"));
      } else {
        if (parseInt(this.form.incomeMin) > parseInt(this.form.incomeMax)) {
          callback(new Error("รายได้เริ่มต้นมากกว่ารายได้สิ้นสุด"));
        } else if (this.form.incomeMin > 90000000000) {
          callback(new Error("กรุณากรอกตัวเลขไม่เกิน 9 หมื่นล้าน"));
        } else {
          callback();
        }
      }
    };
    var validRegister = (rule, value, callback) => {
      if (this.form.registerStart == "" && this.form.registerEnd == "") {
        callback(new Error("กรุณาเลือกวันที่รับสมัคร"));
      } else {
        callback();
      }
    };

    return {
      loadingSave: false,
      stepTwo: false,
      form: {
        isRateReady: false,
        isFactorRateReady: false,
        isGrade: false,
        isIncome: false,
        projectName: "",
        readinessRate: [],
        factorRate: [],
        grade: [],
        incomeMin: "",
        incomeMax: "",
        date: [],
        registerStart: "",
        registerEnd: "",
        dateEvent: [],
        eventDate: "",
        eventDateEnd: "",
        eventLocation: "",
        projectDescription: "",
        image: [],
        registerType: null,
        registerUrl: "",
        ownerName: "",
        conditionProject: [],
        operateType: null,
        projectTeraks: [],
        businessSizeS: false,
        businessSizeM: false,
        businessSizeL: false,
        operators: [],
        industries: [],
        shipments: [],
        exportValues: [],
        paymentTerms: [],
      },
      rules: {
        gradeValid: [{ validator: validGrade, trigger: ["blur", "change"] }],
        incomeMinValid: [{ validator: validIncomeMin, trigger: "blur" }],
        incomeMaxValid: [{ validator: validIncomeMax, trigger: "blur" }],
        registerType: [
          {
            required: true,
            message: "กรุณาเลือกประเภทของการสมัครโครงการ",
            trigger: "change",
          },
        ],
        eventLocation: [
          {
            required: true,
            message: "กรุณากรอกสถานที่จัดกิจกรรม",
            trigger: "blur",
          },
        ],
        registerUrl: [
          {
            required: true,
            message: "กรุณากรอกแนบลิงก์สมัครโครงการ",
            trigger: "blur",
          },
        ],
        ownerName: [
          {
            required: true,
            message: "กรุณากรอกเจ้าของโครงการ",
            trigger: "blur",
          },
        ],
        projectName: [
          {
            required: true,
            message: "กรุณากรอกชื่อกิจกรรม/โครงการ",
            trigger: "blur",
          },
        ],
        eventDate: [
          {
            type: "date",
            required: true,
            message: "กรุณาเลือกวันที่จัดกิจกรรม",
            trigger: "change",
          },
        ],
        dateRegister: [{ validator: validRegister, trigger: "blur" }],
        projectTeraks: [
          {
            required: true,
            message: "กรุณาเลือกโครงการจากระบบ TERAK",
            trigger: "change",
          },
        ],
        conditionProject: [
          {
            required: true,
            message: "กรุณาเลือกเงื่อนไขสำหรับโครงการ",
            trigger: "change",
          },
        ],
        shipments: [
          {
            required: true,
            message: "กรุณาเลือกวงเงินค้าขายต่อ Shipment",
            trigger: ["blur", "change"],
          },
        ],
        operators: [
          {
            required: true,
            message: "กรุณาเลือกประเภทผู้ประกอบการ",
            trigger: "change",
          },
        ],
        industries: [
          {
            required: true,
            message: "กรุณาเลือกประเภทอุตสาหกรรม",
            trigger: "change",
          },
        ],
        exportValues: [
          {
            required: true,
            message: "กรุณาเลือกมูลค่าการส่งออก",
            trigger: "change",
          },
        ],
        paymentTerms: [
          {
            required: true,
            message: "กรุณาเลือกเทอมการชำระเงิน",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    formDataOut(data) {
      if (data.operateType != null) {
        this.stepTwo = true;
        this.form = data;
      } else {
        this.stepTwo = false;
      }
    },
    async projectAdd() {
      this.$refs["form"].validate().then(async (valid) => {
        if (valid) {
          this.loadingSave = true;
          let resImg = "";
          if (this.form.image.length > 0) {
            var formDataTERAK = new FormData();
            formDataTERAK.append("file", this.form.image[0].file.raw);
            var token = jwt.sign(
              { system: "CFOE2021" },
              "Y2weQEzYjje3LRDiwAUiX1U2r",
              { expiresIn: "5m" }
            );

            TERAK.defaults.headers.common.Authorization = token;
            const res = await TERAK.post(`cfoe/file`, formDataTERAK);
            if (res.data.success) {
              resImg = res.data.filename;
            }
          }
          let grade = this.form.grade.findIndex((a) => a === 999);
          if (grade > -1) {
            this.form.grade.splice(grade, 1);
          }
          let industries = this.form.industries.findIndex((a) => a === 999);
          let projectTeraks = this.form.projectTeraks.findIndex(
            (a) => a === 999
          );

          if (industries > -1) {
            this.form.industries.splice(industries, 1);
          }
          if (projectTeraks > -1) {
            this.form.projectTeraks.splice(projectTeraks, 1);
          }

          let forGrade = [];
          let forShipment = [];
          let forExportValue = [];
          let forTerm = [];

          this.form.grade.forEach((row) => {
            forGrade.push(row.toString());
          });

          this.form.shipments.forEach((row) => {
            forShipment.push(row.toString());
          });
          this.form.exportValues.forEach((row) => {
            forExportValue.push(row.toString());
          });
          this.form.paymentTerms.forEach((row) => {
            forTerm.push(row.toString());
          });

          let obj = {
            operateType: this.form.operateType,
            isRateReady:
              this.form.operateType == 2
                ? await this.checkSelectType("readinessStatus")
                : false,
            isFactorRateReady:
              this.form.operateType == 2
                ? await this.checkSelectType("factorStatus")
                : false,
            isGrade:
              this.form.operateType == 2
                ? await this.checkSelectType("gradeStatus")
                : false,
            isIncome:
              this.form.operateType == 2
                ? await this.checkSelectType("incomeStatus")
                : false,

            shipmentStatus:
              this.form.operateType == 2
                ? await this.checkSelectType("shipmentStatus")
                : false,
            exportValueStatus:
              this.form.operateType == 2
                ? await this.checkSelectType("exportValueStatus")
                : false,
            paymentTermStatus:
              this.form.operateType == 2
                ? await this.checkSelectType("paymentTermStatus")
                : false,
            projectTerakStatus:
              this.form.operateType == 2
                ? await this.checkSelectType("projectTERAKStatus")
                : this.form.projectTeraks.length > 0
                ? true
                : false,
            industryStatus:
              this.form.operateType == 2
                ? await this.checkSelectType("industryTypeStatus")
                : false,
            operatorStatus:
              this.form.operateType == 2
                ? await this.checkSelectType("operatorTypeStatus")
                : false,
            businessSizeStatus:
              this.form.operateType == 2
                ? await this.checkSelectType("businessSizeStatus")
                : false,
            businessSizeS: this.form.businessSizeS,
            businessSizeM: this.form.businessSizeM,
            businessSizeL: this.form.businessSizeL,
            grade: forGrade,
            operators: this.form.operators,
            industries: this.form.industries,
            readinessRate:
              (await this.checkSelectType("readinessStatus")) == true
                ? this.form.readinessRate
                : [],
            factorRate:
              (await this.checkSelectType("factorStatus")) == true
                ? this.form.factorRate
                : [],
            incomeMin:
              this.form.incomeMin != "" ? this.form.incomeMin.toString() : "0",
            incomeMax:
              this.form.incomeMax != "" ? this.form.incomeMax.toString() : "0",
            shipments: forShipment,
            exportValues: forExportValue,
            paymentTerms: forTerm,
            projectTeraks: this.form.projectTeraks,

            projectName: this.form.projectName,
            registerStart: this.form.registerStart,
            registerEnd: this.form.registerEnd,
            eventDate: this.form.eventDate,
            eventDateEnd: this.form.eventDateEnd,
            projectDescription: this.form.projectDescription,
            eventLocation: this.form.eventLocation,
            registerType: this.form.registerType.toString(),
            registerUrl: this.form.registerUrl,
            ownerName: this.form.ownerName,
            image: resImg,
          };
          HTTP.post(`project/create`, obj)
            .then((res) => {
              if (res.data.success) {
                this.$message({
                  message: "เพิ่มข้อมูลโครงการสำเร็จ",
                  type: "success",
                  duration: 4000,
                });
                this.$router.push(`/manage/project`);
              } else {
                if (res.data.status == 204) {
                  this.loadingSave = false;
                  this.$message({
                    message: "ชื่อข้อมูลโครงการซ้ำ",
                    type: "error",
                    duration: 4000,
                  });
                } else if (res.data.status == 403) {
                  this.$store.commit("SET_PAGE_ACTIVE", null);
                  this.$router.push("/not-allowed");
                }
              }
            })
            .catch((e) => {
              if (e.response.data.status == 401) {
                this.checkAuth();
              } else {
                this.alertCatchError(e);
              }
            });
        }
      });
    },
    checkSelectType(type) {
      let result = false;
      let find = this.form.conditionProject.find((row) => row == type);
      if (typeof find != "undefined") {
        result = true;
      }
      return result;
    },
  },
};
</script>
